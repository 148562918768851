@font-face {
  font-family: SettingsIcons;
  font-display: swap;
  src: url("./SettingsIcons.woff2") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
}
:root {
  --bg: #eff4f9;
  --txt_clr-rgb: 0 0 0;
  --clr_prm: #0067c0;
  --scroll: rgb(255 255 255 / 80%);
}
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background: var(--bg);
  color: rgb(var(--txt_clr-rgb));
  margin-left: 300px;
  font-size: 14px;
}

nav {
  position: fixed;
  left: 0;
  height: 100vh;
  width: 300px;

  .nav_top {
    .account {
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px;
      margin: 8px;

      &:hover {
        background: rgb(var(--txt_clr-rgb) / 3.33%);
      }

      div p {
        &:first-child {
          font-weight: 500;
        }
        &:last-child {
          font-size: smaller;
        }
      }
    }

    .search {
      background: white;
      height: 32px;
      width: calc(300px - 32px);
      border: 0;
      border-bottom: 1px solid var(--clr_prm);
      border-radius: 4px;
      padding: 8px;
      margin: 8px 16px 20px;

      &:hover {
        background: rgb(255 255 255 / 33%);
      }
      &:focus {
        border-bottom: 2px solid var(--clr_prm);
      }
      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: rgb(var(--txt_clr-rgb) / 66%);
      }
    }
  }

  .nav_bottom {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 152px);
    overflow: overlay;
    position: relative;

    .navLink {
      position: relative;
      overflow: hidden;
      height: 36px;
      padding-left: 7px;
      margin: 2px 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      &:hover,
      &.active {
        background: rgb(var(--txt_clr-rgb) / 3.33%);
      }

      img {
        margin: 0 10.5px 0 7px;
      }
    }

    .marker {
      position: absolute;
      background: var(--clr_prm);
      height: 16px;
      width: 3px;
      border-radius: 3px;
      top: 12px;
      left: 16px;
      transition: top 300ms cubic-bezier(1, 0, 0, 1), height 75ms;

      &.active {
        height: 32px;
      }
    }

    @for $i from 1 to 12 {
      .navLink:nth-child(#{$i}).active ~ .marker {
        top: ((40 * ($i - 1)) + 12) + px;
      }
    }
  }
}

main {
  overflow: hidden;

  h1 {
    padding: 14px 24px;
    font-weight: 500;
    font-size: 28px;
  }

  .tilesCont {
    height: calc(100vh - 66px);
    padding: 0 24px 44px;
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    gap: 4px;
    animation: slideUp 0.2s;

    .tile {
      width: min(100%, 1000px);
      min-height: 67px;
      display: flex;
      align-items: center;
      background: rgb(255 255 255 / 67%);
      border-radius: 4px;
      padding: 7px 42px 7px 0;
      border: 1px solid rgb(0 0 0 / 10%);
      position: relative;

      &:hover {
        background: rgb(255 255 255 / 33%);
      }
      &::after {
        content: ">";
        transform: scaleY(2);
        position: absolute;
        right: 16px;
      }
      span {
        font-size: 20px;
        margin: 0 21px 0 17.5px;
        font-family: SettingsIcons;
      }
      .tile_desc {
        color: rgb(var(--txt_clr-rgb) / 66%);
        font-size: 12.33px;
      }

      &.square {
        width: 220px;
        height: 220px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding: 0;
        font-weight: 500;

        span {
          font-size: 48px;
        }
      }

      &.thin-blue {
        min-height: unset;
        height: unset;
        color: var(--clr_prm);
        justify-content: center;
        margin: 4px 0;
      }

      &.thin-blue::after,
      &.square::after {
        display: none;
      }
    }

    .subHeading {
      font-weight: 500;
      height: 45px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    .spacer {
      height: 12px;
    }
  }
}

.navMenuBtn {
  display: none;
}

::-webkit-scrollbar {
  --scrollbar-top-btn: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.102 16.981c-1.074 0-1.648-1.265-.941-2.073l5.522-6.311a1.75 1.75 0 0 1 2.634 0l5.522 6.311c.707.808.133 2.073-.941 2.073H6.102z' fill='rgb(0 0 0 / 50%)'/%3E%3C/svg%3E");
  --scrollbar-bottom-btn: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.102 8c-1.074 0-1.648 1.265-.941 2.073l5.522 6.311a1.75 1.75 0 0 0 2.634 0l5.522-6.311c.707-.808.133-2.073-.941-2.073H6.102z' fill='rgb(0 0 0 / 50%)'/%3E%3C/svg%3E");

  display: none;
  width: 14px;
  border-radius: 14px;

  &:hover {
    background: var(--scrollbar-bottom-btn) 0% calc(100% - 3px) / contain no-repeat, var(--scrollbar-top-btn) 0% calc(0% + 3px) / contain no-repeat, var(--scroll);
  }

  &-thumb {
    background: rgb(var(--txt_clr-rgb) / 50%);
    background-clip: padding-box;
    border: 6px solid transparent;
    border-radius: 14px;

    &:hover {
      border: 4px solid transparent;
    }
  }

  &-button:single-button {
    height: 14px;
  }
}
*:hover::-webkit-scrollbar {
  display: block;
}

@media (max-width: 800px) {
  .navMenuBtn {
    display: flex;
    position: fixed;
    top: 8px;
    right: 8px;
    padding: 12px;
    z-index: 99;
    border-radius: 6px;

    &:hover {
      background: rgb(var(--txt_clr-rgb) / 5%);
    }

    svg {
      transition: all 0.1s;
    }
    &:active svg {
      transform: scaleX(0.67);
    }
  }

  body {
    margin-left: 0;
  }
  nav {
    transform: translateX(-100%);
    z-index: 9;
    transition: all 0.2s cubic-bezier(0, 0, 0, 1);
    background: var(--bg);

    &.open {
      transform: translateX(0);
    }
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: #202020;
    --txt_clr-rgb: 255 255 255;
    --clr_prm: #4cc2ff;
    --scroll: rgb(44 44 44 / 80%);
  }

  nav .nav_top .search {
    background: rgb(255 255 255 / 3.33%);

    &:hover {
      background: rgb(255 255 255 / 5%);
    }
  }

  main .tilesCont .tile {
    background: rgb(255 255 255 / 5%);

    &:hover {
      background: rgb(255 255 255 / 10%);
    }
  }

  ::-webkit-scrollbar {
    --scrollbar-top-btn: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.102 16.981c-1.074 0-1.648-1.265-.941-2.073l5.522-6.311a1.75 1.75 0 0 1 2.634 0l5.522 6.311c.707.808.133 2.073-.941 2.073H6.102z' fill='rgb(255 255 255 / 50%)'/%3E%3C/svg%3E");
    --scrollbar-bottom-btn: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.102 8c-1.074 0-1.648 1.265-.941 2.073l5.522 6.311a1.75 1.75 0 0 0 2.634 0l5.522-6.311c.707-.808.133-2.073-.941-2.073H6.102z' fill='rgb(255 255 255 / 50%)'/%3E%3C/svg%3E");
  }
}

@keyframes slideUp {
  from {
    transform: translateY(70px);
  }
  to {
    transform: translateY(0);
  }
}
